//
// Shop module styles
// --------------------------------------------------

// Product card

.product-card {
  padding-bottom: $card-spacer-y;
  border: 0;
  transition: $product-card-transition;
  height: 100%;

  .product-card {
    display: grid;
    grid-template-rows: auto 1fr auto;
  }

  .card-body {
    padding: 0;
    display: grid;
    grid-template-rows: auto 1fr auto;

    position: relative;
    background-color: $card-bg;
    z-index: 2;
  }

  .product-card-actions,
  > .btn-wishlist,
  .badge {
    position: absolute;
    top: $spacer * 0.75;
    right: $spacer * 0.75;
    z-index: 5;
  }

  .product-info {
    flex-direction: column;
  }

  .product-price {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
  }

  .product-price .main-price {
    display: flex;
    align-items: baseline;

    .text-muted {
      margin-left: 4px;
    }
  }

  .product-price .fs-sm.text-muted {
    display: flex;
  }

  .card-img-top {
    &::before {
      display: block;
      content: "";
      padding-top: 100%;
      width: 100%;
    }

    picture {
      bottom: 0;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      background: #fff;
      padding: 1em;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      mix-blend-mode: darken;
      color: rgba(var(--cz-dark-rgb), .2);
      font-size: 1rem !important;
    }

    img.default-image {
      width: 35%;
      height: auto;
      margin-left: 32.5%;
      margin-right: 32.5%;
      margin-top: 35%;
      mix-blend-mode: luminosity;
      opacity: .3;
      max-width: 35%;
      display: block;
      text-align: center;
      color: #222;
    }
  }

  &.card-vertical {
    .card-img-top {
      &::before {
        padding-top: calc(4 / 3 * 100%);

        @include media-breakpoint-down(md) {
          padding-top: 125%;
        }
      }
    }
  }

  .product-card-actions .btn-action {
    padding: 0.5rem;
    transition: $product-card-transition;
    @include border-radius($border-radius-sm);
    background-color: $white;
    font-size: $font-size-ms;
    visibility: hidden;
    opacity: 0;
  }

  .badge {
    right: auto;
    left: $spacer * 0.75;
    &.badge-end {
      right: $spacer * 0.75;
      left: auto;
    }
  }

  .btn-primary {
    font-size: 12px !important;
    padding: 6px !important;
  }

  .card-body-hidden {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    margin-top: -$card-spacer-y;
    transition: $product-card-transition;
    @include border-radius($card-border-radius);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: $card-bg;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
  }

  @include media-breakpoint-up(sm) {
    padding-bottom: 0;
  }

  @include media-breakpoint-up(lg) {
    .card-body {
      padding: var(--cz-card-spacer-y) var(--cz-card-spacer-x);
    }

    .product-info {
      flex-direction: row;
    }

    .product-price {
      flex-direction: row;
    }

    .btn-primary {
      font-size: var(--cz-btn-font-size) !important;
      padding: var(--cz-btn-padding-y) var(--cz-btn-padding-x) !important;
    }

    &:hover:not(.card-static) {
      border-color: $card-bg !important;
      box-shadow: $product-card-hover-box-shadow;
      z-index: 10;
      .product-card-actions .btn-action {
        opacity: 1;
        visibility: visible;
      }
      .card-body-hidden {
        opacity: 1;
        visibility: visible;
        box-shadow: $product-card-hover-box-shadow;
      }
    }
  }
}

// List variant

.product-list .product-list-thumb {
  @include border-radius($card-border-radius);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
@include media-breakpoint-up(sm) {
  .product-list {
    .product-list-thumb {
      width: $product-list-thumb-width;
      @include border-radius($card-border-radius);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      overflow: hidden;
    }
    .card-body-hidden {
      top: 50%;
      margin-top: 0;
      transform: translateY(-50%);
      box-shadow: none !important;
    }
  }
}
@include media-breakpoint-up(lg) {
  .product-list:hover + .border-top {
    opacity: 0;
  }
}

// Alternative product card

.product-card-alt {
  border: 0;

  .card-body {
    padding: $spacer ($spacer * 0.625);
  }

  .product-thumb {
    position: relative;
    @include border-radius($card-border-radius);
    overflow: hidden;

    > img {
      display: block;
      width: 100%;
    }
  }

  .product-thumb-overlay {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $product-card-thumb-overlay-bg;
    z-index: 1;
  }

  .btn-wishlist {
    top: $spacer * 0.75;
    right: $spacer * 0.75;
    background-color: $white;
    z-index: 5;
    visibility: hidden;
  }
  .badge {
    position: absolute;
    right: auto;
    left: $spacer * 0.75;
    z-index: 5;
  }

  .product-card-actions {
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    text-align: center;
    z-index: 5;
    visibility: hidden;
  }

  .product-thumb-overlay,
  .btn-wishlist,
  .product-card-actions {
    position: absolute;
    transition: $product-card-transition;
    opacity: 0;
  }

  @include media-breakpoint-up(lg) {
    .product-thumb:hover {
      .product-thumb-overlay,
      .btn-wishlist,
      .product-card-actions {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

// Product title

.product-title > a {
  transition: $product-title-link-transition;
  color: $product-title-link-color;
  text-decoration: none !important;
  &:hover {
    color: $product-title-link-hover-color;
  }
}

// Product meta

.product-meta {
  transition: $product-meta-transition;
  color: $product-meta-color;
  text-decoration: none !important;
  &:hover {
    color: $product-meta-hover-color;
  }
}

// Floating button

.product-floating-btn {
  position: absolute;
  right: 0.75rem;
  bottom: 0.75rem;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  z-index: 10;

  .product-card:hover & {
    opacity: 1;
  }
}

// Star rating

.star-rating {
  display: inline-block;
  white-space: nowrap;
  line-height: 1;
  vertical-align: middle;
  .star-rating-icon {
    display: inline-block;
    margin-right: 0.1875rem;
    color: $star-color;
    font-size: $star-size;
    vertical-align: middle;
    &.active {
      color: $star-active-color;
    }
  }
}

// Shop specific page title that overlaps the content below

.page-title-overlap {
  padding-bottom: 6.375rem;
  & + * {
    margin-top: -4.875rem;
  }
  @include media-breakpoint-down(md) {
    padding-bottom: 5.5rem;
  }
}

// Interactive credit card

.credit-card-wrapper {
  margin: 1.875rem -0.9375rem;
  @include media-breakpoint-down(sm) {
    .jp-card-container {
      width: 16.25rem !important;
    }
    .jp-card {
      min-width: 15.625rem !important;
    }
  }
}

// Product details (Single product)

@include media-breakpoint-up(lg) {
  .product-details {
    width: 100%;
    max-width: 26rem;
    padding-top: 0.25rem;
  }
}

// Poduct availability badge (Single product)

.product-badge {
  position: absolute;
  top: 50%;
  right: -1.75rem;
  padding: {
    top: 0.425rem;
    left: 0.625rem;
    right: 1rem;
    bottom: 0.425rem;
  }
  transform: translateY(-50%);
  @include border-radius($border-radius);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: $font-size-ms;

  &::after {
    position: absolute;
    bottom: -0.475rem;
    right: 0.25rem;
    height: 0;
    width: 0;
    transform: rotate(-45deg);
    border: solid transparent;
    content: " ";
    pointer-events: none;
    border-width: 0.525rem;
  }

  > i {
    margin: {
      top: -0.125rem;
      right: 0.25rem;
    }
    font-size: 1.2em;
    vertical-align: middle;
  }

  &.product-available {
    background-color: $success;
    color: $white;
    &::after {
      border-color: rgba($success, 0);
      border-bottom-color: darken($success, 10%);
    }
  }
  &.product-not-available {
    background-color: $gray-200;
    color: $gray-700;
    &::after {
      border-color: rgba($gray-200, 0);
      border-bottom-color: darken($gray-200, 8%);
    }
  }
  @include media-breakpoint-down(lg) {
    right: -0.75rem;
  }
}
.modal .product-badge {
  right: -0.575rem;
}

// Product gallery

.product-gallery {
  display: flex;
}
.product-gallery-thumblist {
  margin-left: -($product-gallery-thumb-spacing * 0.8);
}
.product-gallery-thumblist-item {
  display: block;
  position: relative;
  width: $product-gallery-thumb-size;
  height: $product-gallery-thumb-size;
  margin: $product-gallery-thumb-spacing;
  transition: $product-gallery-thumb-transition;
  border: $product-gallery-thumb-border-width solid
    $product-gallery-thumb-border-color;
  @include border-radius($product-gallery-thumb-border-radius);
  text-decoration: none !important;
  overflow: hidden;
  > img {
    display: block;
    width: 100%;
    transition: opacity 0.2s ease-in-out;
    opacity: 0.6;
  }
  .product-gallery-thumblist-item-text {
    position: absolute;
    top: 50%;
    width: 100%;
    padding: 0.25rem;
    transform: translateY(-50%);
    color: $product-gallery-thumb-color;
    font-size: $product-gallery-thumb-font-size;
    text-align: center;
    > i {
      display: block;
      margin-bottom: 0.25rem;
      font-size: 1.5em;
    }
  }
  &:hover {
    border-color: $product-gallery-thumb-hover-border-color;
    > img {
      opacity: 1;
    }
  }
  &.active {
    border-color: $product-gallery-thumb-active-border-color;
    > img {
      opacity: 1;
    }
  }
}
.product-gallery-preview {
  position: relative;
  width: 100%;
  margin-top: $product-gallery-thumb-spacing;

  @include media-breakpoint-up(sm) {
    margin-left: $product-gallery-thumb-spacing;
  }
}
.product-gallery-preview-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: $product-gallery-preview-transition;
  opacity: 0;
  z-index: 1;
  > img {
    display: block;
    width: 100%;
  }
  &.active {
    position: relative;
    opacity: 1;
    z-index: 10;
  }
  &:hover {
    cursor: crosshair;
  }
}
@include media-breakpoint-down(sm) {
  .product-gallery {
    display: block;
  }
  .product-gallery-thumblist {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: {
      right: -$spacer;
      left: -$spacer;
    }
    padding-top: $spacer;
  }
  .product-gallery-thumblist-item {
    margin: $product-gallery-thumb-spacing * 0.5;
  }
}
.drift-zoom-pane {
  z-index: $zindex-fixed;
}
